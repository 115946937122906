import Label from "components/Label";
import Field from "components/Field";
import Hint, { HintType } from "components/Hint";
import Input from "components/Input";
import InputWithAddon from "components/InputWithAddon";
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
    Ref,
} from "react";
import * as S from "./style";
import {
    validateEnsAddress,
    validatePositiveNumber,
    validateWalletAddress,
} from "utils/formValidation";
import { isAddress, JsonRpcProvider } from "ethers";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";
import { GeneralTokenDetailsResponse } from "api";
import { isDomain } from "utils/strings";
import { hasNameService } from "utils/solanan";

export interface PaymentReceiver {
    // User type this, it can be a eth address or ens domain
    toAddressOrEnsDomain?: string;

    // We set/look up this for clarity
    toAddress?: string;
    ensDomain?: string;

    amount?: string;
    notes?: string;
}

export interface PaymentReceiverFieldsetProps {
    paymentReceiver: PaymentReceiver;
    index: number;
    onChange: (updatedPaymentReceiver: PaymentReceiver) => void;
    formEnabled?: boolean;
    usd: boolean;
    selectedToken: GeneralTokenDetailsResponse;
    networkHexId: string;
}

export type PaymentReceiverFieldsetRef = {
    validate: () => boolean;
};

function PaymentReceiverFieldset(
    {
        paymentReceiver,
        index,
        onChange,
        formEnabled = true,
        usd,
        selectedToken,
        networkHexId,
    }: PaymentReceiverFieldsetProps,
    ref: Ref<PaymentReceiverFieldsetRef>
) {
    const { getNetworkById } = useAvailableNetworks();
    // This could be problematic if network number `networkHexId` is unavailable
    const availableNetwork = getNetworkById(networkHexId);
    const provider = new JsonRpcProvider(availableNetwork?.rpcUrl);

    const [ensDomainError, setEnsDomainError] = useState<boolean>(false);

    const isEthAddress =
        paymentReceiver.toAddress === paymentReceiver.toAddressOrEnsDomain;
    const isEnsDomain =
        paymentReceiver.ensDomain === paymentReceiver.toAddressOrEnsDomain;

    const invalidEnsDomainMessage = "Please enter a valid ENS domain";
    const invalidEthAddressMessage = "Please enter a valid wallet address";

    const toAddressOrEnsDomainRef = useRef<HTMLInputElement>(null);
    const amountRef = useRef<HTMLInputElement>(null);
    const validate = () => {
        const validAmount = amountRef.current
            ? validatePositiveNumber({
                  input: amountRef.current,
              })
            : false;

        const validToAddress =
            paymentReceiver.toAddress && paymentReceiver.toAddress.length > 0
                ? isAddress(paymentReceiver.toAddress)
                : false;

        if (!validToAddress && toAddressOrEnsDomainRef.current) {
            toAddressOrEnsDomainRef.current.setCustomValidity(
                isEnsDomain ? invalidEnsDomainMessage : invalidEthAddressMessage
            );
        }

        return validToAddress && validAmount;
    };

    useImperativeHandle(ref, () => ({ validate }));

    const lookupEnsDomain = async (ensAddress: string) => {
        var ethAddress = await provider.resolveName(ensAddress);
        if (ethAddress) {
            onChange({
                toAddress: ethAddress,
                ensDomain: ensAddress,
            });
        } else {
            setEnsDomainError(true);
        }
    };

    const lookupEthAddress = async (ethAddress: string) => {
        let ensName;
        try {
            ensName = await provider.lookupAddress(ethAddress.toLowerCase());
        } catch (error) {
            //console.error("error", error);
        }

        if (ensName) {
            onChange({
                toAddress: ethAddress,
                ensDomain: ensName,
            });
        } else {
            onChange({
                toAddress: ethAddress,
            });
        }
    };

    const lookupToAddressOrEnsDomain = async (toAddressOrEnsDomain: string) => {
        // Not all ENS addresses end with `.eth`, unfortunately - need another solution here
        const isEnsDomain = toAddressOrEnsDomain.endsWith(".eth");
        const isEthAddress = isAddress(toAddressOrEnsDomain);

        if (isEnsDomain) {
            lookupEnsDomain(toAddressOrEnsDomain);
        } else if (isEthAddress) {
            lookupEthAddress(toAddressOrEnsDomain);
        }
    };

    const onChangeToAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Update the value instantly
        onChange({
            // User typed this
            toAddressOrEnsDomain: event.target.value,

            // Reset as this will be looked up in the useEffect below
            toAddress: "",
            ensDomain: "",
        });

        // Reset ensDomain / error
        setEnsDomainError(false);
    };

    // When address is updated, we wait 0.5s to avoid lookup on every keystroke
    useEffect(() => {
        const timer = setTimeout(() => {
            if (paymentReceiver.toAddressOrEnsDomain) {
                lookupToAddressOrEnsDomain(
                    paymentReceiver.toAddressOrEnsDomain
                );
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [paymentReceiver.toAddressOrEnsDomain]);

    const onChangeNotes = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            notes: event.target.value,
        });
    };

    const onChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            amount: event.target.value,
        });
    };

    useEffect(() => {
        if (ensDomainError && toAddressOrEnsDomainRef.current) {
            toAddressOrEnsDomainRef.current.setCustomValidity(
                "Please enter a valid ENS domain"
            );
        }
    }, [ensDomainError, toAddressOrEnsDomainRef]);

    let decimals = 2;
    if (!usd) {
        decimals = selectedToken.decimals;
    }
    const amountInputSteps = (1 / 10 ** decimals).toFixed(decimals);
    const amountInputValue = paymentReceiver.amount;

    return (
        <>
            <S.Fieldset>
                <S.GridColumn col={3}>
                    <Field>
                        {index === 0 && (
                            <Label htmlFor={`toAddress-${index}`} required>
                                Wallet Address
                                {hasNameService(availableNetwork)
                                    ? ` / ENS`
                                    : ``}
                            </Label>
                        )}
                        <Input
                            type="text"
                            disabled={!formEnabled}
                            name={`toAddress-${index}`}
                            value={paymentReceiver.toAddressOrEnsDomain}
                            onChange={onChangeToAddress}
                            innerRef={toAddressOrEnsDomainRef}
                            onBlur={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                const value = event.target.value;
                                if (
                                    isDomain(value) &&
                                    hasNameService(availableNetwork)
                                ) {
                                    validateEnsAddress({
                                        input: event.target,
                                        reportValidity: false,
                                    });
                                } else {
                                    validateWalletAddress({
                                        input: event.target,
                                        reportValidity: false,
                                        chain: availableNetwork?.chain,
                                    });
                                }
                            }}
                            placeholder="vitalik.eth / 0x1234..."
                        />
                        {ensDomainError && (
                            <Hint type={HintType.Error}>
                                ENS domain not found
                            </Hint>
                        )}
                        {isEthAddress && paymentReceiver.ensDomain && (
                            <Hint>{paymentReceiver.ensDomain}</Hint>
                        )}
                        {isEnsDomain && paymentReceiver.toAddress && (
                            <Hint>
                                <DynamicAddressDisplay
                                    address={paymentReceiver.toAddress}
                                    networkId={networkHexId}
                                />
                            </Hint>
                        )}
                    </Field>
                    <Field>
                        {index === 0 && <Label htmlFor="notes">Notes</Label>}
                        <Input
                            name="notes"
                            value={paymentReceiver.notes}
                            placeholder="Additional notes"
                            onChange={onChangeNotes}
                        />
                    </Field>
                    <Field>
                        {index === 0 && (
                            <Label htmlFor={`amount-${index}`} required>
                                Amount
                            </Label>
                        )}
                        <InputWithAddon
                            inputProps={{
                                type: "number",
                                disabled: !formEnabled,
                                name: `amount-${index}`,
                                placeholder: "0",
                                innerRef: amountRef,
                                value: amountInputValue,
                                onBlur: (event) =>
                                    validatePositiveNumber({
                                        input: event.target,
                                        reportValidity: false,
                                    }),
                                onChange: onChangeAmount,
                                min: 0,
                                step: amountInputSteps,
                            }}
                            addon={{
                                content: usd
                                    ? "$"
                                    : selectedToken
                                    ? selectedToken.symbol
                                    : "",
                                position: usd ? "left" : "right",
                            }}
                        />
                    </Field>
                </S.GridColumn>
            </S.Fieldset>
        </>
    );
}
export default forwardRef(PaymentReceiverFieldset);

import { createElement, Fragment } from "react";
import { PublicKey } from "@solana/web3.js";
import { isAddress } from "ethers";
import { distance } from "fastest-levenshtein";
import { onlyUnique } from "utils/arrays";
import { regex } from "utils/regex";

const firstToUpper = (str: string, remainingLower = false) => {
    return (
        str.charAt(0).toUpperCase() +
        (remainingLower ? str.slice(1).toLowerCase() : str.slice(1))
    );
};

const firstToLower = (str: string) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
};

const isDomain = (domain: string): boolean => {
    return regex.domain.test(domain);
};

const isTxHash = (hash: string): boolean => {
    const evmTxFormat = /^0x[a-fA-F0-9]{64}$/;

    // Sol signatures are base58 encoded and are between 43 & 88 characters long
    // The base58 alphabet excludes 0, O, I, and l characters
    const solTxFormat = /^[1-9A-HJ-NP-Za-km-z]{43,88}$/;

    return evmTxFormat.test(hash) || solTxFormat.test(hash);
};

const isSolAddress = (addr: string): boolean => {
    try {
        return PublicKey.isOnCurve(new PublicKey(addr));
    } catch (e) {
        return false;
    }
};

const shortenAddress = (addr: string) => {
    if (isSolAddress(addr))
        return addr.slice(0, 4).concat("...").concat(addr.slice(-4));

    // The check to ensure ENS names don't get shortened
    if (isDomain(addr) || (!isAddress(addr) && !isTxHash(addr))) return addr;
    return addr.slice(0, 6).concat("...").concat(addr.slice(-4));
};

export enum Transform {
    UPPER = "UPPER",
    LOWER = "LOWER",
}
interface ToSafeStr {
    (
        str: string,
        options?: {
            spaces?: string;
            transform?: Transform;
        }
    ): string;
}
const toSafeStr: ToSafeStr = (str, options) => {
    const spaces = options?.spaces || `-`;
    const transform = options?.transform || undefined;

    let safeStr = str.replace(/[^a-zA-Z0-9\s]/g, ``).replace(/\s+/g, spaces);

    if (transform === Transform.UPPER) {
        safeStr = safeStr.toUpperCase();
    } else if (transform === Transform.LOWER) {
        safeStr = safeStr.toLowerCase();
    }

    return safeStr;
};

const parseFormattedNumber = (value: string) => {
    const parsedValue = value.replace(/,/g, "");
    return Number(parsedValue);
};

const toSentence = (words: string[]): string => {
    if (words.length === 0) return "";
    if (words.length === 1) return words[0];
    if (words.length === 2) return words.join(" & ");

    const allButLast = words.slice(0, -1).join(", ");
    const lastWord = words[words.length - 1];

    return `${allButLast} & ${lastWord}`;
};

const checkLevenshtein = (
    strToCheck: string,
    setToCompare: string[],
    tolerance: number = 2
): string | null => {
    const str = strToCheck.toLowerCase();

    let closest = ``;
    let minDistance = Infinity;

    setToCompare.forEach((comparison) => {
        const levenshtein = distance(str, comparison);
        if (levenshtein < minDistance && levenshtein <= tolerance) {
            minDistance = levenshtein;
            closest = comparison;
        }
    });

    if (closest && closest !== str) return closest;

    return null;
};

const fixArticles = (str: string): string => {
    // Regular expression to match "a" or "A" followed by a space and a word (ignoring punctuation) starting with a vowel
    const regex = /\b(a|A)(?=\W*[aeiouAEIOU])/gi;

    return str.replace(regex, (match) => {
        return match === "A" ? "An" : "an";
    });
};

const ListValuesFromIds = ({
    objects,
    pks,
    matchProp,
    valueProp,
    wrapper,
}: {
    objects: {
        [key: string]: any;
    }[];
    pks: (number | string)[];
    matchProp: string;
    valueProp: string;
    wrapper?: React.ElementType;
}) => {
    const values = pks
        .filter(onlyUnique)
        .reduce((acc: (string | number)[], pk: number | string) => {
            const obj = objects.find((obj) => obj[matchProp] === pk);
            if (obj) acc.push(obj[valueProp]);
            return acc;
        }, [])
        .reduce(
            (
                acc: React.ReactNode[],
                value: string | number,
                index: number,
                values: (string | number)[]
            ) => {
                const formattedValue =
                    typeof value === `string` ? firstToUpper(value) : value;
                const element = wrapper
                    ? createElement(wrapper, {}, formattedValue)
                    : formattedValue;

                if (index === 0) {
                    acc.push(<Fragment key={index}>{element}</Fragment>);
                } else if (index === values.length - 1) {
                    acc.push(<Fragment key={index}> and {element}</Fragment>);
                } else {
                    acc.push(<Fragment key={index}>, {element}</Fragment>);
                }

                return acc;
            },
            []
        );

    return values.length ? <>{values}</> : null;
};

export {
    firstToUpper,
    firstToLower,
    toSentence,
    isDomain,
    isTxHash,
    isSolAddress,
    shortenAddress,
    toSafeStr,
    parseFormattedNumber,
    checkLevenshtein,
    fixArticles,
    ListValuesFromIds,
};
